<template>
  <div class="mid-center">
    <div class="image-context"></div>
    <div class="text">{{text}}</div>
  </div>
</template>

<script>
export default {
  name: 'constructorItem',
  props: {
    text: {
      default: '开发中...'
    }
  }
}
</script>

<style lang='scss' src="./constructor-item.scss" scoped></style>
