<template>
  <div class="main-page">
    <constructor-item :text="emptyText"></constructor-item>
  </div>
</template>

<script>
import constructorItem from '@/components/common/constructor-item/constructor-item.vue'
export default {
  components: { constructorItem },
  name: 'interviewMinutes',
  data () {
    return {
      emptyText: '提供对供应商、资产进行访谈记录功能，正在开发中...'
    }
  }
}
</script>

<style lang="scss" scoped>
.main-page {
  height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  position: relative;
}
</style>
